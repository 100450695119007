import React from 'react';
import Button from '../button';
import FileInput from './file_input';
import ColorInput from './color_input';
import DateInput from './date_input';
import Fetch from '../blocks/list/fetch'

// Get window.Field dynamically, since it isn't available yet at load time.
function Field(props) {
  return window.Field(props);
}

const SelectOption = (props) => (
  <option value={props.value}>{props.label}</option>
);

function InputField(props) {
  return (
    <React.Fragment>
      {props.label && <label className={props.labelClass}>{props.label}</label>}
      <Field
        component={props.component}
        type={props.type || 'text'}
        name={props.name}
        placeholder={props.placeholder}
        autoFocus={props.autofocus || props.autoFocus}
        autoComplete={props.autocomplete || props.autoComplete}
        className={'form-control ' + props.fieldClass}
      />
    </React.Fragment>
  )
}

function InputCheckbox(props) {
  const checked = props.values && ['t', 'true', true].includes(props.values[props.name]);
  return (
    <div className="form-check">
      <Field
        type={props.type || 'text'}
        name={props.name}
        checked={checked}
        className={'form-check-input ' + props.fieldClass}
      />
      {props.label && <label className='form-check-label'>{props.label}</label>}
    </div>
  )
}

function InputSelect(props) {
  return (
    <React.Fragment>
      {props.label && <label className={props.labelClass}>{props.label}</label>}
      <Field
        component={props.component}
        type={props.type || 'text'}
        name={props.name}
        className={'form-control ' + props.fieldClass}
      >
        {props.options && props.options.map(o => <SelectOption key={o.value} {...o} />)}
      </Field>
    </React.Fragment>
  )
}

function InputRadio(props) {
  return (
    <React.Fragment>
      {props.label && <label className={props.labelClass}>{props.label}</label>}
      {props.options && props.options.map(o =>
        <div key={o.value} className="form-check">
          <Field
            type={props.type}
            name={props.name}
            id={o.value}
            value={o.value}
            className={'form-check-input ' +  + props.fieldClass}
          />
          {<label className='form-check-label'>{o.value}</label>}
        </div>
      )}
    </React.Fragment>
  )
}

function InputFile(props) {
  const fileListKey = '/block_data/' + props.type

  const files = appState.getNamedSignals()[fileListKey]
  const selectList = [{
    label: 'None selected',
    value: null
  }]

  if(files && files.list) {
    files.list.forEach(function(file) {
      selectList.push({
        label: file.filename,
        value: file.id
      })
    })
  }

  return (
    <React.Fragment>
      {props.label && <label className={props.labelClass}>{props.label}</label>}
      <Field
        type='hidden'
        name={props.name}
      />
      <FileInput
        setFieldValue={props.setFieldValue}
        fieldName={props.name}
        fileType={props.type}
        fileId={props.values && props.values[props.name]}
      />
      <Field
        component='select'
        type='text'
        name={props.name}
        className={'form-control ' + props.fieldClass}
      >
        {selectList && selectList.map(o => <SelectOption key={o.value} {...o} />)}
      </Field>
      <Fetch
        signalsKey={fileListKey}
      />
      <Button
        handleClick={(e) => { e.preventDefault(); props.setFieldValue(props.name, '')}}
        label='Remove Image'
        type='none'
        buttonClass='btn'
      />
    </React.Fragment>
  )
}

function InputColor(props) {
  return (
    <React.Fragment>
      {props.label && <label className={props.labelClass}>{props.label}</label>}
      <Field
        type='hidden'
        name={props.name}
      />
      <ColorInput
        setFieldValue={props.setFieldValue}
        fieldName={props.name}
        value={props.values && props.values[props.name]}
      />
    </React.Fragment>
  )
}

function InputDate(props) {
  return (
    <React.Fragment>
      {props.label && <label className={props.labelClass}>{props.label}</label>}
      <Field
        type='hidden'
        name={props.name}
      />
      <DateInput
        setFieldValue={props.setFieldValue}
        fieldName={props.name}
        value={props.values && props.values[props.name]}
      />
    </React.Fragment>
  )
}

function InputWrapper(props) {
  var input = null;
  if (props.type == 'radio') {
    input = InputRadio(props);
  } else if (props.type == 'checkbox') {
    input = InputCheckbox(props);
  } else if (props.component == 'select') {
    input = InputSelect(props);
  } else if (props.type == 'image') {
    input = InputFile(props);
  } else if (props.type == 'audio') {
    input = InputFile(props);
  } else if (props.type == 'color') {
    input = InputColor(props);
  } else if (props.type == 'date') {
    input = InputDate(props);
  } else {
    input = InputField(props);
  }

  return (
    <div className={"form-group " + props.className}>
      {input}
      <span className='error'>
        {props.touched[props.name] && props.errors[props.name]}
      </span>
    </div>
  );
}

export default InputWrapper
