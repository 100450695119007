import React from 'react';
import Dropzone from 'react-dropzone'
import LoadingSvg from '../../svg/loading-svg';

const AjaxAPI = window.AjaxAPI;

class FileInput extends React.Component {
  constructor (props) {
    super(props);

    this.state = { loading: false }
  }

  onDrop(files) {
    const _this = this
    const props = this.props
    const post_data = {
      'block_type': props.fileType,
      'file': files[0]
    };

    this.setState({ loading: true })
    ;(new AjaxAPI).sendFile(post_data, '/files', function(data){
      const fileId = data.data.id;
      props.setFieldValue(props.fieldName, fileId)
      _this.setState({ loading: false })
    });
  }

  accept() {
    switch (this.props.fileType) {
      case 'image':
        return ['image/png', 'image/jpg', '.png', '.jpg']
      case 'audio':
        return ['audio/mpeg', '.mp3']
      default:
        return []
    }
  }

  imagePreview(url) {
    const preview = url + '?version=preview'
    return (
      <div
        className='image-frame center'
        style={{ backgroundImage : `url(${preview})` }} >
      </div>
    )
  }

  audioPreview(url) {
    const preview = url
    return (
      <div
        className='image-frame center'
      >
        Audio
      </div>
    )
  }

  preview() {
    const url = this.props.fileId ?
      (['/files', this.props.fileType, this.props.fileId].join('/')) : null

    switch (this.props.fileType) {
      case 'image':
        return url ? this.imagePreview(url) : <div/>
      case 'audio':
        return url ? this.audioPreview(url) : <div/>
      default:
        return []
    }
    if (isImage) {
      return
    }
  }

  fileSelectList() {
    const files = appState.getNamedSignals()[this.fileListKey]
    const selectList = [{
      label: 'None selected',
      value: null
    }]

    files.forEach(function(file, i) {
      selectListist.push({
        label: file.name,
        value: file.id
      })
    })

    return selectList
  }

  render() {
    const fileId = this.props.fileId
    const preview = this.preview()
    const message = "Drop file here, or click to upload."
    const loading = this.state.loading
    const loadingSvg = <LoadingSvg/>

    return (
      <div className='flex-wrapper'>
        <div className='flex-pane dropzone'>
          {this.preview()}
        </div>
        <div className='flex-pane dropzone'>
          <Dropzone
            onDrop={this.onDrop.bind(this)}
            accept={this.accept()}
            className='inline'
          >
            <div className='message'>
              {loading ? loadingSvg : message}
            </div>
          </Dropzone>
        </div>
      </div>
    )
  }
}

export default FileInput
