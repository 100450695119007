import React from 'react';
import { ChromePicker } from 'react-color';

class ColorInput extends React.Component{
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(color, event) {
    const value = color.rgb
    const rgba = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
    this.props.setFieldValue(this.props.fieldName, rgba)
  }

  rgbaToValue(rgba) {
    if (!rgba) {
      return ''
    }

    const values = rgba.match(/[.?\d]+/g)
    return {
      r: parseInt(values[0]),
      g: parseInt(values[1]),
      b: parseInt(values[2]),
      a: parseFloat(values[3])
    }
  }

  render() {
    return (
      <ChromePicker
        color={this.rgbaToValue(this.props.value)}
        onChange={this.onChange}
      />
    )
  }
}

export default ColorInput
