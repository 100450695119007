import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class DateInput extends React.Component{
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(value, event) {
    this.props.setFieldValue(this.props.fieldName, value)
  }

  render() {
    const value = this.props.value

    return (
      <DatePicker
        selected={(value && new Date(value)) || null}
        onChange={this.onChange}
      />
    )
  }
}

export default DateInput
